import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/4oir4zgCpM4">
    <SEO title="Staying on the Right Side - Crossing Rivers" />
  </Layout>
)

export default SermonPost
